<template>
    <v-container fluid>
      <v-row dense>
        <v-col cols="12" class="title">
          {{title}}
          <div class="subheading mt-1" v-if="subtitle">{{subtitle}}</div>
        </v-col>
        <v-col v-if="treeView" cols="12">
          <v-radio-group v-model="registration.division" @change="onChange">
            <v-expansion-panels v-model="tree.open" multiple v-if="tree">
              <v-expansion-panel
                v-for="branch in tree.items"
                :key="branch.name"
              >
              <v-expansion-panel-header class="px-2 title">
                {{branch.name}}
                <template v-slot:actions>
                  <v-icon color="color3">
                    fas fa-caret-down
                  </v-icon>
                </template>

              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-expansion-panels v-model="branch.open" multiple>
                  <v-expansion-panel
                    v-for="limb in branch.children"
                    :key="limb.name"
                    class="grey lighten-4"
                  >
                  <v-expansion-panel-header class="px-2 title">
                    {{limb.name}}
                    <template v-slot:actions>
                      <v-icon color="color3">
                        fas fa-caret-down
                      </v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-expansion-panels v-model="limb.open" multiple>
                      <v-expansion-panel
                        v-for="leaf in limb.children"
                        :key="leaf.name"
                      >
                      <v-expansion-panel-header>
                        {{leaf.name}}
                        <template v-slot:actions>
                          <v-icon color="color3">
                            fas fa-caret-down
                          </v-icon>
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <template v-for="d in leaf.divisions">
                          <v-radio
                            color="color3"
                            :label="`${d.name}${(d.regClosed || !filteredDivisions.includes(d.id)) ? ' - Closed' : d.isFull && !d.acceptanceOnly ? ' - Waitlist' : ''}`"
                            :value="d"
                            :key="d.id"
                            :disabled="(!adminQuickAdd && (d.regClosed || !filteredDivisions.includes(d.id))) && !(user && user.vbl && !view.public)"
                          ></v-radio>
                          <v-expand-transition :key="`${d.key}_selected`">
                            <div class="subheading ml-4" v-if="registration.division && registration.division.id === d.id && registration.division.currentWindows.length > 1">
                              Please select an option below
                              <v-radio-group
                                v-model="registration.window"
                                class="mt-0"
                              >
                                <v-radio
                                  color="color3"
                                  v-for="w in registration.division.currentWindows"
                                  :label="`${w.name} ${w.feeString}`"
                                  :value="w"
                                  :key="w.id"
                                  :disabled="d.disabled && !d.disableOverride"
                                ></v-radio>
                              </v-radio-group>
                            </div>
                          </v-expand-transition>
                        </template>
                      </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-radio-group>
        </v-col>
        <v-col cols="12" v-else>
          <v-radio-group v-model="registration.division" @change="onChange">
            <template v-for="d in regDivisions">
              <v-radio
                color="color3"
                :label="`${d.name}${(d.regClosed) ? ' - Closed' : !filteredDivisions.includes(d.id) ? ' - Bid Required' : d.isFull && !d.acceptanceOnly ? ' - Waitlist' : ''}`"
                :value="d"
                :key="d.id"
                :disabled="divisionDisabled(d)"
              ></v-radio>
              <v-expand-transition :key="`${d.key}_selected`">
                <div class="subheading ml-4" v-if="registration.division && registration.division.id === d.id && registration.division.currentWindows.length > 1">
                  Please select an option below
                  <v-radio-group
                    v-model="registration.window"
                    class="mt-0"
                  >
                    <v-radio
                      color="color3"
                      v-for="w in registration.division.currentWindows"
                      :label="`${w.name} ${w.feeString}`"
                      :value="w"
                      :key="w.id"
                      :disabled="d.disabled && !d.disableOverride"
                    ></v-radio>
                  </v-radio-group>
                </div>
              </v-expand-transition>
            </template>
          </v-radio-group>
        </v-col>
        <v-col cols="12" v-if="registration.reservation">
          <div>
            You currently have a reservation for the selected divsion. Changing divisions requires canceling your reservation.
          </div>
          <div v-if="editDialog" class="pt-3">
            To cancel your reservation delete it from the cart
          </div>
          <div v-else>
            <v-btn color="error" text small @click.stop="$emit('cancel-reservation')">cancel reservation</v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import uniq from 'lodash.uniq'

export default {
  props: ['tournament', 'registration', 'adminQuickAdd', 'active', 'editDialog'],
  data () {
    return {
      tree: null,
      resyW: 0
    }
  },
  computed: {
    ...mapGetters(['user', 'view']),
    treeView () {
      return this.tournament.props.includes('tree')
    },
    title () {
      return this.registration.bid ? 'Congrats on earning your bid!' : 'Let\'s start by selecting a division'
    },
    subtitle () {
      return this.registration.bid && this.filteredDivisions.length === 1 ? `This bid is valid for the ${this.registration.division.name} division` : null
    },
    divisions () {
      const r = this.registration.reservation
      const wl = r && r.waitlist
      console.log(r)

      return this.tournament.publicDivisions.map(d => {
        const resyDisable = r && r.tournamentDivisionId !== d.id
        return {
          label: d.isFull || wl ? `${d.name} - Waitlist` : d.name,
          labelNew: `${d.name}${(d.regClosed || !this.filteredDivisions.includes(d.id)) ? ' - Closed' : d.isFull || wl ? ' - Waitlist' : ''}`,
          value: d,
          key: d.id,
          disabled: resyDisable || (!this.adminQuickAdd && (d.regClosed || !this.filteredDivisions.includes(d.id))),
          disableOverride: this.user && this.user.vbl && false,
          divisionId: d.division.id,
          genderId: d.gender.id,
          gender: d.gender,
          ageType: d.ageType,
          numOfPlayers: d.numOfPlayers
        }
      })
    },
    regDivisions () {
      var divs = [...this.tournament.publicDivisions]
      if (this.view.admin) {
        divs.push(...this.tournament.refDivisions)
      }
      return divs
    },
    bidRestrict () {
      return this.registration.bid && this.registration.bid.divisionRestrict
    },
    filteredDivisions () {
      var divisions = this.regDivisions
      if (this.bidRestrict) {
        divisions = this.registration.bid.getAllowedDivisions(this.tournament)
      } else if (this.tournament.bidFirst && !this.tournament.bidProgram) {
        divisions = divisions.filter(f => !f.bidProgram)
      }

      return divisions.map(m => m.id)
    }
  },
  methods: {
    divisionDisabled (d) {
      const r = this.registration.reservation
      const resyDisable = r && r.tournamentDivisionId !== d.id
      return resyDisable || (!this.adminQuickAdd && (d.regClosed || !this.filteredDivisions.includes(d.id)))
    },
    selectById (id) {
      this.registration.setDivision(this.tournament.publicDivisions.find(d => d.id === id))
    },
    onChange () {
      this.registration.initPlayers()
      const windows = this.registration.division.registrationWindows.filter(f => f.isCurrent)
      if (windows.length === 1) {
        this.registration.window = windows[0]
      } else {
        this.registration.window = null
        if (windows.length === 0 && (this.adminQuickAdd || (this.user && this.user.id === 1))) {
          this.registration.window = this.registration.division.lastRegistrationWindow
        }
      }
    },
    createTree () {
      const divisions = this.tournament.publicDivisions
      const ages = uniq(divisions.map(m => m.ageType.name)).sort()
      const genders = uniq(divisions.map(m => m.gender.name)).sort()
      const types = uniq(divisions.map(m => m.numOfPlayers)).sort()
      const tree = { open: [], items: [] }

      ages.forEach((a, ai) => {
        genders.forEach((g, gi) => {
          types.forEach((t, ti) => {
            const d = divisions.filter(f => f.ageType.name === a && f.gender.name === g && f.numOfPlayers === t)
            if (d.length) {
              tree.open.push(ai)
              let branch = tree.items.find(f => f.name === a)
              if (!branch) {
                branch = { name: a, children: [], open: [] }
                tree.items.push(branch)
              }
              let limb = branch.children.find(f => f.name === g)
              if (!limb) {
                limb = { name: g, children: [], open: [] }
                branch.children.push(limb)
              }
              let leaf = limb.children.find(f => f.name === `${t}'s`)
              if (!leaf) {
                leaf = { name: `${t}'s`, divisions: d }
                limb.children.push(leaf)
              }
            }
          })
        })
      })
      tree.open = tree.items.map((m, i) => i)
      tree.items.forEach(f => {
        f.open = f.children.map((m, i) => i)
        f.children.forEach(l => {
          l.open = l.children.map((m, i) => i)
        })
      })

      this.tree = tree
    }
  },
  watch: {
    filteredDivisions: function (val) {
      if (val && val.length === 1) {
        this.selectById(val[0])
      }
    },
    active: function (v) {
      if (v && this.registration.division && !this.registration.window) {
        this.onChange()
      }
    }
  },
  mounted () {
    this.createTree()
  }
}
</script>

<style scoped>
>>> .v-expansion-panel-content__wrap {
  padding: 9px !important;
}
</style>
