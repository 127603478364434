import { nanoid } from 'nanoid'
import sum from 'lodash.sum'
import round from 'lodash.round'
import { groupFees } from './HelperFunctions'
import CartFeeFlattener from './CartFeeFlattener'
import { pipeArray } from '@/Filters'

export default class CartItem {
  constructor (dto) {
    this.id = 0
    this.organization = null
    this.leadId = 0
    this.name = null
    this.type = 'TR'
    this.description = null
    this.amount = null
    this.quantity = 1
    this.registration = null
    this.product = null
    this.status = null
    this.promo = null
    this.emails = []
    this.jot = null
    this.error = false
    this.preAuth = false
    this.linkId = `${nanoid(9)}`
    this.cartFees = []
    this.refundPolicyId = null
    this.availability = 99

    if (dto) {
      this.update(dto)
    }
  }

  // methods
  update (dto) {
    if (typeof dto === 'string') dto = JSON.parse(dto)
    Object.assign(this, dto)
  }

  statusApplies (status) {
    if (this.registration && this.registration[status.idField] === status.id) {
      return true
    }
    if (status.isProduct) {
      return this.productObj && this.productObj.id === status.id
    }
    return false
  }

  statusUpdate (status) {
    if (this.registration && this.registration[status.idField] === status.id) {
      // set preAuth
      this.preAuth = status.preAuth
      this.preAuthMessage = status.preAuthMessage
      if (status.memberships && status.memberships.length) {
        status.memberships.forEach(m => {
          const p = this.registration.players.find(f => f.playerProfileId === m.id)
          if (p) {
            p.memberships = m.memberships
          }
        })
      }
      this.cartFees = CartFeeFlattener(this.registration, (status.cartFees || []))
      if (status.windowId && this.registration.windowId !== status.windowId) {
        this.registration.windowId = status.windowId
      }
      // set amount in case it changed
      const skipAmountChanges = this.registration && this.registration.minAmount
      if (!skipAmountChanges) {
        var players = this.type === 'PA' ? 1 : status.truePerPlayer ? this.registration.players.filter(f => !!f.firstName).length : this.registration.players.length
        var newAmount = status.feePerTeam ? status.fee : status.fee * players
        if (this.product) {
          var u = JSON.parse(this.product)
          if (u.unlimitedDiscounts) {
            newAmount -= u.unlimitedDiscounts.map(m => m.val).reduce((a, b) => a + b, 0)
          }
        }
        this.amount = newAmount
      }

      if (this.type !== 'TR') return status

      // set status
      this.status = status.status
      console.log(status.status)
      if (status.status === 'AcceptanceOnly') this.registration.waitlist = 1
      if (['Closed', 'AcceptanceOnly'].includes(status.status)) return status
      // if (typeof status.fee === 'number' && this.amount !== status.fee) this.amount = status.fee
      if (!this.registration.reservation) {
        this.registration.waitlist = status.availability-- < 1
      }
      if (this.registration.waitlist) {
        if (!status.paidWL) this.amount = 0
        this.status = 'Full'
      }
      return status
    }
    if (status.isProduct && this.statusApplies(status)) {
      this.availability = status.availability
      if (status.amount !== -13) this.amount = status.amount
      this.cartFees = CartFeeFlattener(this.registration, (status.cartFees || []))
    }
    return status
  }

  get playerEmails () {
    const base = this.registration && this.registration.players ? this.registration.players.filter(player => player.email).map(player => player.email) : [...this.emails]
    if (this.registration && this.registration.extraFields) {
      Object.keys(this.registration.extraFields).filter(f => f.toLowerCase().includes('email')).forEach(k => {
        base.push(this.registration.extraFields[k])
      })
    }
    const p = this.product && JSON.parse(this.product)
    if (p && p.shippingInfo && p.shippingInfo.email) {
      base.push(p.shippingInfo.email)
    }

    return base
  }

  get isLead () {
    return this.registration && this.registration.players.filter(player => player.email || player.lastName || player.phone).length
  }

  get subtotal () {
    return this.amount * this.quantity
  }

  get promoTotal () {
    const p = this.promo ? (this.promo.amount || this.promoAmount) : 0
    return p * this.quantity
  }

  get total () {
    const p = this.promo ? (this.promo.amount || this.promoAmount) : 0
    return (this.amount * this.quantity) + (p * this.quantity)
  }

  get feeTotal () {
    return sum(this.cartFees.filter(f => !f.isTax).map(m => {
      if (m.amount > 0 || m.amount < 0) return m.amount
      if (m.percentage > 0 || m.percentage < 0) return round(this.total * m.percentage, 2)
      return 0
    }))
  }

  get taxTotal () {
    return sum(this.cartFees.filter(f => f.isTax).map(m => {
      if (m.amount > 0) return m.amount
      if (m.percentage > 0) return round(this.total * m.percentage, 2)
      return 0
    }))
  }

  getFeeInfo (tax) {
    const fees = this.cartFees.filter(f => f.isTax === !!tax)
    return fees.length ? groupFees(fees, this.total) : null
  }

  get productObj () {
    return this.product ? JSON.parse(this.product) : null
  }

  newMembership (program, level, profile, org) {
    const product = {
      levelId: level.id,
      memberId: profile.id,
      details: level,
      organization: program.organization
    }
    if (profile.playerProfileId && profile.id === profile.playerProfileId) {
      product.profile = profile.tournamentRegistrationDto
    }
    if (product.profile) product.profile.vblId = profile.vblId = this.linkId
    this.organization = org || program.organization
    this.name = program.title
    this.type = 'MEM'
    const fullname = profile.fullName || `${profile.firstName} ${profile.lastName}`
    const pId = profile.id ? ` (${profile.id})` : ''
    this.description = `${level.name} for ${fullname}${pId}`
    this.amount = level.price
    this.product = JSON.stringify(product)
    profile.email && !profile.email.includes('***') && this.emails.push(profile.email)
    profile.parent && profile.parent.email && this.emails.push(profile.parent.email)
    this.jot = { text: `${profile.firstName}'s ${level.name} Number`, value: profile.id }
  }

  newMembership2 (program, level, profile, org, avpInfo) {
    const product = {
      levelId: level.id,
      memberId: '0',
      details: level,
      organization: program.organization,
      profile: profile.tournamentRegistrationDto || {
        id: profile.id,
        firstName: profile.firstName,
        lastName: profile.lastName,
        club: profile.club
      },
      avpInfo: avpInfo
    }
    product.profile.password = profile.password
    if (product.profile) product.profile.vblId = profile.vblId = this.linkId
    this.organization = org || program.organization
    this.name = program.title
    this.type = 'MEM'
    const fullname = profile.fullName || `${profile.firstName} ${profile.lastName}`
    const pId = profile.id ? ` (${profile.id})` : ''
    this.description = `${level.name} for ${fullname}${pId}`
    this.amount = level.price
    this.product = JSON.stringify(product)
    profile.email && !profile.email.includes('***') && this.emails.push(profile.email)
    profile.parent && profile.parent.email && this.emails.push(profile.parent.email)
  }

  newProduct (product, extra) {
    this.type = 'PROD'
    this.organization = product.organization
    const names = [product.title]

    let p = product
    while (p.options.length > 0) {
      p = p.selectedOption
      if (!p) return
      names.push(p.title)
    }

    this.name = pipeArray(names.filter(f => !!f))
    this.amount = +(p.price === -13 ? p.altAmount : p.price)
    const dto = Object.assign({}, p.purchaseDto, extra)
    this.product = JSON.stringify(dto)
    const player = p.player
    if (player) {
      const fullname = player.fullName || `${player.firstName} ${player.lastName}`
      this.description = `For: ${fullname}`
      player.email && !player.email.includes('***') && this.emails.push(player.email)
    }
  }

  newOrderPayment (order) {
    this.organization = order.organization
    this.name = 'Order Payment'
    this.type = 'OPAY'
    this.description = `Payment for Order Id: ${order.id}`
    this.amount = order.total
    this.product = JSON.stringify({ orderId: order.id })
    this.emails.push(order.emailReceiptTo)
  }

  newSplitPayment (dto) {
    this.organization = dto.mtr.organization
    this.name = 'Partial Payment'
    this.type = 'MTRPAY'
    this.description = `Payment for: ${dto.mtr.description}`
    this.amount = dto.amount
    this.product = JSON.stringify({
      moneyTrailRecordId: dto.mtr.id,
      amount: dto.amount
    })
  }
}
