<template>
  <v-dialog
    v-model="dialog"
    class="mr-0"
    scrollable
    persistent
  >
    <template v-slot:activator="{ on: d }">
      <v-tooltip bottom v-if="false">
        <template v-slot:activator="{ on: tt }">
          <v-btn fab small color="color3Text color3--text" v-on="{ ...d, ...tt }">
            <v-icon>fas fa-user-plus</v-icon>
          </v-btn>
        </template>
        <span>Edit Registration</span>
      </v-tooltip>
    </template>

    <v-card>
      <v-toolbar color="color1 color1Text--text">
        <v-toolbar-title>Edit Registration</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn text icon color="color3Text" @click="onCancelClick">
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-toolbar>
      <loading-bar :value="loading"></loading-bar>
      <v-card-text>
        <tournament-registration
          ref="stepper"
          :tournament="myTournament"
          :registration="registration"
          :edit="true"
          @save-click="onSaveClick"
          :edit-dialog="true"
        ></tournament-registration>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Tournament from '@/classes/Tournament'
import RegistrationUI from './Registration.vue'
import { mapGetters } from 'vuex'

export default {
  props: ['fab', 'small', 'cartItem', 'dialog'],
  data () {
    return {
      registration: null,
      loading: false,
      myTournament: null
    }
  },
  computed: {
    ...mapGetters(['tournament'])
  },
  methods: {
    loadTournament (id) {
      this.loading = true
      this.$VBL.tournament.getById(id)
        .then(r => {
          this.internalTournament = new Tournament(this.$VBL, r.data)
        })
        .catch(e => console.log(e.response))
        .finally(() => {
          this.loading = false
        })
    },
    getTournament () {
      return new Promise((resolve, reject) => {
        if (this.tournament && this.tournament.id === this.cartItem.registration.tournamentId) return resolve(this.tournament)
        // Need to load the tournament
        this.$VBL.tournament.getById(this.cartItem.registration.tournamentId)
          .then(r => resolve(new Tournament(this.$VBL, r.data)))
          .catch(e => reject(e))
      })
    },
    init () {
      this.loading = true
      this.getTournament()
        .then(r => {
          this.myTournament = r
          this.registration = this.myTournament.newRegistration(this.cartItem.registration.tournamentDivisionId)
          this.registration.update(this.cartItem.registration)
          this.$refs.stepper.leadId = this.cartItem.leadId
          this.cartItem.stepHistory.push('edit_cart_item')
          this.$refs.stepper.stepHistory = this.cartItem.stepHistory
        })
        .finally(() => {
          this.loading = false
        })
    },
    onSaveClick (dto) {
      this.$emit('save-click', dto)
    },
    onCancelClick () {
      this.$emit('cancel-click')
      setTimeout(() => {
        this.registration = null
        this.myTournament = null
      }, 333)
    }
  },
  watch: {
    dialog: function (val) {
      if (val) this.init()
    }
  },
  components: {
    'tournament-registration': RegistrationUI
  }
}
</script>

<style>

</style>
