import * as mutations from '../MutationTypes'
// import Cart from '../../classes/Cart'
import * as actions from '../ActionTypes'
import sum from 'lodash.sum'
import max from 'lodash.max'
import flatten from '@/helpers/ArrayFlatten'
import Vue from 'vue'
import moment from 'moment'

export default {
  state: {
    carts: [],
    reservations: []
  },
  mutations: {
    SET_RESERVATION (state, payload) {
      if (payload) {
        const i = state.reservations.findIndex(f => f.id === payload.id)
        if (i === -1) {
          state.reservations.push(payload)
        } else {
          state.reservations.splice(i, 1, payload)
        }
      }
    },
    UPDATE_RESERVATION (state, payload) {
      if (payload) {
        const i = state.reservations.findIndex(f => f.id === payload.id)
        if (i > -1) {
          state.reservations.splice(i, 1, payload)
        } else {
          for (let i = 0; i < state.carts.length; i++) {
            const c = state.carts[i]
            c.items.forEach(item => {
              if (item.registration && item.registration.reservation && item.registration.reservation.id === payload.id) {
                item.registration.reservation = payload
              }
            })
          }
        }
      }
    },
    CLEAR_RESERVATION (state, payload) {
      const i = state.reservations.findIndex(f => f.id === payload.id)
      if (i !== -1) {
        state.reservations.splice(i, 1)
      }
    },
    [mutations.ADD_CART_ITEM] (state, newItem) {
      let i = state.carts.findIndex(f => f.organization.id === newItem.organization.id)
      if (i === -1) {
        state.carts.push({ organization: newItem.organization, items: [], status: true, stripeAccount: null })
        i = state.carts.length - 1
      }
      const cart = state.carts[i]
      let id = 1
      if (cart.items.length) {
        id = max(cart.items.map(item => item.id)) + 1
      }
      newItem.id = id
      cart.items.push(newItem)
    },
    [mutations.SET_CART_STATUS] (state, dto) {
      const cart = state.carts.find(f => f.organization.id === dto.organization.id)
      if (!cart) return

      Object.assign(cart, dto.organization)
      cart.status = dto.status
      cart.stripeAccount = dto.stripeAccount
      cart.paymentType = dto.organization.paymentType
      cart.allowPayLater = dto.organization.allowPayLater
    },
    [mutations.REMOVE_CART_ITEM] (state, item) {
      const i = state.carts.findIndex(f => f.organization.id === item.organization.id)
      if (i === -1) return

      const cart = state.carts[i]
      const x = cart.items.findIndex(f => f.id === item.id)
      if (x !== -1) {
        cart.items.splice(x, 1)
      }
    },
    [mutations.CLEAN_CARTS] (state) {
      for (let i = 0; i < state.carts.length; i++) {
        const cart = state.carts[i]
        if (!cart.items.length || cart.orderId || cart.organization === null) state.carts.splice(i, 1)
      }
    },
    [mutations.UPDATE_CART_ITEM] (state, item) {
      const i = state.carts.findIndex(f => f.organization.id === item.organization.id)
      if (i === -1) return

      const cart = state.carts[i]
      const cartItem = cart.items.find(f => f.id === item.id)
      cartItem && Object.assign(cartItem, item)
    },
    [mutations.CLEAR_CART] (state, organizationId) {
      const i = state.carts.findIndex(f => f.organization.id === organizationId)
      if (i === -1) return

      const cart = state.carts[i]
      cart.items = []
    },
    [mutations.SET_CART_ORDER_ID] (state, payload) {
      const cart = state.carts.find(f => f.organization.id === payload.organizationId)
      if (!cart) {
        console.log('Cart not found')
        return
      }
      cart.orderId = payload.orderId
      cart.emailReceipt = payload.emailReceipt
      cart.jot = [{ text: 'Your Order Id', value: payload.orderId }]
      cart.jot.push(...payload.jot)
    },
    [mutations.APPLY_PROMO] (state, payload) {
      const cart = state.carts.find(f => f.organization.id === payload.organizationId)
      if (!cart) {
        console.log('Cart not found')
        return
      }
      cart.promo = payload.code
    },
    [mutations.CLEAR_PROMO] (state, payload) {
      const cart = state.carts.find(f => f.organization.id === payload)
      if (!cart) {
        console.log('Cart not found')
        return
      }
      cart.items.forEach(i => { i.promo = null })
      cart.promo = null
    }
  },
  actions: {
    [actions.REMOVE_CART_ITEM] ({ commit, dispatch }, payload) {
      commit(mutations.REMOVE_CART_ITEM, payload)
      if (payload.reservation && payload.reservation.id) {
        dispatch('CANCEL_RESERVATION', payload.reservation)
      }
      commit(mutations.CLEAN_CARTS)
    },
    [actions.CHECK_PROMO] ({ commit, dispatch }, payload) { // payload = {organizationId, code, items }
      return new Promise((resolve, reject) => {
        const sdk = Vue.prototype.$VBL
        sdk.cart.applyPromo(payload)
          .then(r => {
            commit(mutations.CLEAR_PROMO, payload.organizationId)
            if (r.data.items) {
              r.data.items.forEach(i => {
                payload.items = r.data.items
                dispatch(actions.APPLY_PROMO, payload)
              })
              resolve()
            } else {
              resolve(r.data)
            }
          })
          .catch(e => reject(e))
      })
    },
    [actions.APPLY_PROMO] ({ commit, state }, payload) {
      const cart = state.carts.find(f => f.organization.id === payload.organizationId)
      if (!cart) {
        console.log('Cart not found')
        return
      }

      payload.items.forEach(i => {
        i.organization = cart.organization
        commit(mutations.UPDATE_CART_ITEM, i)
      })
      commit(mutations.APPLY_PROMO, payload)
    },
    [actions.CHECK_CART_STATUS] ({ commit, getters }, organizationId) {
      return new Promise((resolve, reject) => {
        const cart = getters.getCart(organizationId)
        if (!cart) return reject(new Error(`Can't find cart with id: ${organizationId}`))
        const sdk = Vue.prototype.$VBL
        sdk.cart.getStatus(organizationId)
          .then(r => {
            commit(mutations.SET_CART_STATUS, r.data)
            return resolve()
          })
          .catch(error => {
            console.log(error.response)
            return reject(error)
          })
      })
    },
    [actions.PURCHASE_COMPLETE] ({ commit }, payload) {
      commit(mutations.SET_CART_ORDER_ID, payload)
      commit(mutations.CLEAR_CART, payload.organizationId)
    },
    [actions.ADD_CART_ITEM] ({ commit, state, dispatch }, payload) {
      commit(mutations.CLEAN_CARTS)
      commit(mutations.ADD_CART_ITEM, payload)
      if (payload.registration && payload.registration.reservation) {
        console.log('here')
        commit('CLEAR_RESERVATION', payload.registration.reservation)
      }
      const cart = state.carts.find(f => f.organization.id === payload.organization.id)
      if (!cart) {
        console.log('Cart not found')
        return
      }
      if (cart.promo) {
        dispatch(actions.CHECK_PROMO, {
          organizationId: cart.organization.id,
          code: cart.promo,
          items: cart.items
        })
      }
    },
    CANCEL_RESERVATION ({ commit }, payload) {
      commit('CLEAR_RESERVATION', payload)
      return new Promise((resolve, reject) => {
        const sdk = Vue.prototype.$VBL
        sdk.http.post('tournament/reservation/cancel', payload)
          .then(r => {
            return resolve()
          })
          .catch(error => {
            console.log(error.response)
            return reject(error)
          })
      })
    },
    KEEP_ALIVE ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const sdk = Vue.prototype.$VBL
        sdk.http.get(`tournament/reservation/keep-alive?id=${payload.id}`)
          .then(r => {
            commit('UPDATE_RESERVATION', r.data)
            return resolve()
          })
          .catch(error => {
            console.log(error.response)
            return reject(error)
          })
      })
    },
    CLEAN_RESERVATIONS ({ commit, state, getters }) {
      return new Promise((resolve, reject) => {
        const inCart = getters.reservationInfo.inCart
        if (state.reservations.length) {
          console.log('Cleaning...')
          state.reservations = state.reservations.filter(f => !!f)
          const a = []
          for (let i = 0; i < state.reservations.length; i++) {
            const r = state.reservations[i]
            var ic = inCart.find(f => f.id === r.id)
            const x = moment(r.expiry)
            if (x.isBefore(moment()) || ic) {
              console.log('EXPIRED')
              continue
            }
            // console.log(r)
            a.push(r)
          }
          state.reservations = a
        }
        if (state.carts.length) {
          console.log('Cleaning Carts...')
          const staleItems = []
          for (let i = 0; i < state.carts.length; i++) {
            const c = state.carts[i]
            c.items.forEach(item => {
              if (item.registration && item.registration.reservation) {
                const r = item.registration.reservation
                const x = moment(r.expiry)
                if (x.isBefore(moment())) {
                  console.log('EXPIRED')
                  staleItems.push(item)
                }
              }
            })
          }
          if (staleItems.length) {
            staleItems.forEach(si => {
              commit(actions.REMOVE_CART_ITEM, si)
            })
            commit(mutations.CLEAN_CARTS)
          }
        }
        return resolve()
      })
    }
  },
  getters: {
    carts: (state) => {
      return state.carts
    },
    getCart: (state) => (organizationId) => {
      return organizationId ? state.carts.find(f => f.organization.id === organizationId) : state.carts[0]
    },
    cartCount: state => {
      return state.carts.length
    },
    cartsItemCount: state => {
      return sum(
        flatten(
          state.carts.map(m => m.items.map(f => f.quantity))
        )
      )
    },
    checkReservations: (state) => (tournamentId) => {
      return state.reservations.find(f => f.tournamentId === tournamentId)
    },
    reservations: (state) => {
      return state.reservations
    },
    reservationInfo: (state) => {
      const inCart = []
      for (let i = 0; i < state.carts.length; i++) {
        const c = state.carts[i]
        c.items.forEach(item => {
          if (item.registration && item.registration.reservation) {
            inCart.push(item.registration.reservation)
          }
        })
      }
      return {
        inProcess: state.reservations,
        inCart: inCart,
        count: state.reservations.length + inCart.length,
        get all () {
          return [...this.inProcess, ...this.inCart].sort((a, b) => new Date(a.expiry) - new Date(b.expiry))
        }
      }
    }
  }
}
